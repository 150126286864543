<template>
  <div>
    <el-container>
      <!-- 头部区域 -->
      <el-header height="80px">
        <el-menu mode="horizontal" active-text-color="#ffd04b" text-color="#000" style="height: 80px;">
          <el-menu-item>
            <div class="head">
              <div class="logo"></div>
              <div style="font-size: 20px;font-weight: 700;">浙江壳牌燃油有限公司</div>
            </div>
          </el-menu-item>
        </el-menu>
      </el-header>
      <!-- 主内容区域 -->
      <el-main></el-main>
      <!-- 底部区域 -->
      <el-footer height="40px">
        Copyright ©2016-Now 浙江壳牌燃油有限公司(zjshell.com), All Rights Reserved.
        ICP备案号：<a class="icp" href="https://beian.miit.gov.cn/">浙ICP备17023404号-1</a>
      </el-footer>
    </el-container>
    <el-carousel class="el-carousel" :height="screenheight" indicator-position="none" arrow="never">
      <el-carousel-item>
        <el-image style="width: 100%;height: 100%"
          :src="require('../../assets/images/single_random_login_bg1.jpg')"
          fit="cover"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image style="width: 100%;height: 100%"
          :src="require('../../assets/images/single_random_login_bg2.jpg')"
          fit="cover"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image style="width: 100%;height: 100%"
          :src="require('../../assets/images/single_random_login_bg3.jpg')"
          fit="cover"></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image style="width: 100%;height: 100%"
          :src="require('../../assets/images/single_random_login_bg4.jpg')"
          fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data () {
    return {
      screenheight: document.documentElement.clientHeight + 'px'
    }
  },
  mounted () {
    window.onresize = () => {
      return () => {
        this.screenheight = document.documentElement.clientHeight + 'px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-item{
  font-size: 1rem;
}
.head {
  display: flex;
  align-items: center;
}

.el-header {
  padding: 0;
}

.el-carousel {
  width: 100%;
  z-index: 1;
}

.el-container {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 2;
  position: absolute;
}

.el-main {
  width: 100%;
}

.el-menu {
  width: 100%;
}

.el-footer {
  background-color: #fbce07;
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
}

.logo {
  background-image: url('../../assets/images/shell_logo.svg');
  width: 80px;
  height: 80px;
}

.icp {
  color: #000;
}
</style>
